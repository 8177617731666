<template>
    <div id='ForgottenPasswordComponent'>
        <h1>Reset your password</h1>
        <v-form
            ref="form"
            v-model="valid"
        >
            <v-text-field
                v-model="email"
                :rules="Rules.email"
                label="Email"
                required
                prepend-icon="mdi-account"
            ></v-text-field>
            <div class="d-flex justify-center align-center column">
                <v-btn
                    :disabled="!valid"
                    color="blue"
                    class="white"
                    width="100%"
                    @click="reset()"
                >
                <v-icon>mdi-email</v-icon> Reset Password
                </v-btn>
                <p>- OR -</p>
                <v-btn
                    color="blue"
                    class="login"
                    dark
                    @click="login()"
                >
                <v-icon>mdi-login</v-icon> Back to Login
                </v-btn>
            </div>
        </v-form>
    </div>
</template>
<script>
export default {
  name: 'ForgottenPasswordComponent',
  props: {
    //
  },
  data: () => ({
    email: '',
    valid: false,
    errors: []
  }),
  methods: {
    login () {
      this.$emit('reset', null)
    },
    reset () {
      const self = this
      const formData = new FormData()
      formData.append('email', self.email)
      axios.post(self.URLs.auth.password.reset, formData)
        .then(response => {

        })
        .catch(error => {
          self.errors = error.response.data.errors
        })
      this.$emit('reset', this.email)
      this.$refs.form.reset()
    }
  },
  computed: {
    //
  },
  created () {
    //
  }
}
</script>
<style lang='scss'>
    @import './resources/sass/variables';
    #ForgottenPasswordComponent {
        width: 70%;
        h1 {
            color: $flo-blue;
        }
        .login {
            width: 100%;
            @media screen and (min-width: 768px) {
            width: 50%;
            }
        }
        .white {
            color: white;
        }
        p {
            margin: .5rem 0;
            font-size: 11px;
            font-style: italic;
            color: $flo-blue;
        }
    }
</style>
