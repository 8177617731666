export const Rules = {
  required: [
    v => (!!v || (v === 0)) || 'This field is required'
  ],
  string: [
    v => !!v || 'This field is required',
    v => (v || '').length <= 50 || 'Field must be less than 50 characters'
  ],
  optionalString: [
    v => (v || '').length <= 50 || 'Field must be less than 50 characters'
  ],
  email: [
    v => !!v || 'E-mail is required',
    v => /.+@.+/.test(v) || 'E-mail must be valid',
    v => (v || '').indexOf(' ') < 0 || 'No spaces are allowed'
  ],
  name: [
    v => !!v || 'Name is required',
    v => (v || '').indexOf(' ') < 0 || 'No spaces are allowed'
  ],
  noSpaces: [
    v => !!v || 'Field is required',
    v => (v || '').indexOf(' ') < 0 || 'No spaces are allowed'
  ],
  password: [
    v => !!v || 'A password is required',
    v => (v || '').length >= 8 || 'Password must be more than 8 characters'
  ],
  optionalPassword: [
    v => (v || '').length >= 8 || 'Password must be more than 8 characters'
  ],
  domain: [
    v => /^(((?!-))(xn--|_{1,1})?[a-z0-9-]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9][a-z0-9-]{0,60}|[a-z0-9-]{1,30}\.[a-z]{2,})$/.test(v) || 'Must have a valid domain name'
  ],
  positiveNumber: [
    v => (v > 0) || 'Field must be a positive number'
  ],
  phone: [
    v => !!v || 'Phone Number is required',
    v => /[0-9+]{11,13}/.test(v) || 'Between 11-13 digits required (+, 0-9)'
  ],
  title: [
    v => !!v || 'A title is required',
    v => (v || '').length <= 50 || 'title must be less than 50 characters'
  ],
  summary: [
    v => !!v || 'A summary is required',
    v => (v || '').length <= 100 || 'Summary must be less than 100 characters'
  ],
  description: [
    v => !!v || 'A description is required',
    v => (v || '').length <= 1000 || 'Description must be less than 1000 characters'
  ],
  smallImage: [
    v => (v || '').size < 2000000 || 'Image size should be less than 2 MB'
  ],
  sortCode: [
    v => /^(?!(?:0{6}|00-00-00))(?:\d{6}|\d\d-\d\d-\d\d)$/.test(v) || 'Invalid sort code'
  ],
  accountNumber: [
    v => /^[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}$/.test(v) || 'Invalid account number'
  ]
}
