export default {
  core: {
    auth: {
      register: '/register/',
      login: '/login',
      logout: '/logout'
    },
    aws: {
      root: 'https://flo-mwcportal.s3.eu-west-2.amazonaws.com/'
    },
    messages: {
      root: '/api/messages/',
      recent: '/api/messages/recent',
      withId: function (id) {
        return this.root + id
      },
      withUser: function (userId) {
        return '/api/messages/user/' + userId
      }
    },
    documents: {
      root: '/api/documents/',
      global: '/api/documents/global',
      recent: '/api/documents/recent',
      withId: function (id) {
        return this.root + id
      }
    },
    mwcs: {
      root: '/api/mwcs/',
      withId: function (id) {
        return this.root + id
      }
    },
    polls: {
      root: '/api/polls/',
      pollsForUser: '/api/polls/polls-for-user',
      withId: function (id) {
        return this.root + id
      }
    },
    pollAnswers: {
      root: '/api/poll-answers/'
    },
    tileEditors: {
      root: '/api/tile-editors/',
      withId: function (id) {
        return this.root + id
      }
    },
    users: {
      root: '/api/users/',
      updatePassword: '/api/updatePassword',
      self: '/self',
      withId: function (id) {
        return this.root + id
      }
    }
  }
}
