/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

/* === Import Statements === */
import Vuetify from 'vuetify'
import { URLs } from './helpers/urls'
import { Rules } from './helpers/Rules'

require('./bootstrap')

window.Vue = require('vue')

/* === Use Statements === */
Vue.use(Vuetify)

/* === Miscellaneous === */
const vuetify = new Vuetify()

/* === Mixins === */
Vue.mixin({
  data: function () {
    return {
      get URLs () {
        return URLs
      },
      get Rules () {
        return Rules
      }
    }
  }
})

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./auth/', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({ // eslint-disable-line no-unused-vars
  vuetify,
  Rules,
  URLs,
  el: '#authApp'
})
