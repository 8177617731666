<template>
    <div id='LoginComponent'>
        <v-form
            ref="form"
            v-model="valid"
        >
            <v-text-field
                v-model="loginData.email"
                :rules="Rules.email"
                label="Email"
                required
                autofocus
                prepend-icon="mdi-account"
                :disabled="loading"
                :error-messages="errors"
                @input="errors = []"
            ></v-text-field>

            <v-text-field
                v-model="loginData.password"
                :rules="Rules.password"
                label="Password"
                @click:append="showPassword = !showPassword"
                @input="errors = []"
                :append-icon="(showPassword ? 'mdi-eye-off' : 'mdi-eye')"
                :type="(showPassword ? 'text' : 'password')"
                prepend-icon="mdi-lock"
                required
                :disabled="loading"
            ></v-text-field>
            <v-btn
                :disabled="!valid"
                color="blue"
                class="mr-4 white"
                :loading="loading"
                width="100%"
                @click="login"
            >
            <v-icon>mdi-login</v-icon> Login
            </v-btn>
        </v-form>
        <div class="d-flex justify-end small-text">
            <v-checkbox
              v-model="loginData.remember"
              label="Remember me"
              color="blue"
            ></v-checkbox>
            <v-spacer></v-spacer>
            <p class="forgotten small-text" @click="forgotten()">Forgotten Password?</p>
        </div>
    </div>
</template>
<script>
export default {
  name: 'LoginComponent',
  props: {
    //
  },
  data: () => ({
    valid: false,
    showPassword: false,
    loginData: {
      remember: false,
      email: '',
      password: ''
    },
    loading: false,
    errors: []
  }),
  methods: {
    login () {
      this.loading = true
      axios.post(this.URLs.core.auth.login, this.loginData).then(
        response => {
          window.location.reload()
        },
        error => {
          this.errors = ['Email or password is incorrect']
          this.loading = false
          this.$emit('loginFailed', error)
        }
      )
    },
    forgotten () {
      this.$emit('forgotten')
    }
  },
  computed: {
    //
  },
  created () {
    //
  }
}
</script>
<style lang='scss'>
    #LoginComponent {
        width: 70%;
        margin-top: 60px;
        h1 {
            color: #4286c7;
        }
        .white {
            color: white;
        }
        .small-text {
            &, & label {
                font-size: 12px!important;
            }
        }
        .forgotten {
            cursor: pointer;
            font-style: italic;
            color: #4286c7;
            padding-top: 1rem;
            transition-duration: 0.4s;
            display: flex;
            align-items: center;
            &:hover {
                color: #1b5892;
            }
        }
    }
</style>
