export default {
  admin: {
    auth: {
      register: '/register/',
      login: '/login',
      logout: '/logout'
    },
    aws: {
      root: 'https://flo-mwcportal.s3.eu-west-2.amazonaws.com/'
    },
    mwcs: {
      root: '/api/mwcs/'
    },
    users: {
      root: '/api/users/',
      updatePassword: '/api/updatePassword'
    },
    tileEditors: {
      root: '/api/tile-editors/',
      withId: function (id) {
        return this.root + id
      }
    },
    slides: {
      root: '/api/slide-editor/',
      withId: function (id) {
        return this.root + id
      }
    },
    polls: {
      root: '/api/polls/',
      withId: function (id) {
        return this.root + id
      }
    },
    roles: {
      root: '/api/roles/'
    },
    pollOptions: {
      root: '/api/poll-options/'
    },
    documents: {
      root: '/api/documents/',
      withId: function (id) {
        return this.root + id
      }
    },
    reports: {
      statistics: '/dashboard-statistics'
    },
    logs: {
      documents: '/api/logs/documents',
      users: '/api/logs/users',
      mwcs: '/api/logs/mwcs'
    }
  }
}
