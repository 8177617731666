<template>
    <div id='AuthComponent' class="d-flex column justify-center align-center">
        <div class="full-page d-flex justify-center align-center">
            <div class="card d-flex justify-center align-center" :style="{borderLeft: borderLeft}">
                <div class="logo"></div>
                <div class="inner-card">
                    <transition  name="slide">
                        <login-component v-if="login" @forgotten="login = !login" @loginFailed="loginFailed"></login-component>
                        <forgotten-password-component v-else  @reset="reset($event)"></forgotten-password-component>
                    </transition>
                </div>
            </div>
            <div class="bottom-bar">
                <p>
                    My Work Co-op by Flo
                </p>
            </div>
        </div>
        <v-snackbar
            v-model="snackbar.show"
            color="green"
            :timeout="4000"
            >
            Password reset email sent to {{ snackbar.email }}
        </v-snackbar>
    </div>
</template>
<script>
export default {
  name: 'AuthComponent',
  props: {
    //
  },
  data: () => ({
    login: true,
    snackbar: {
      show: false,
      email: ''
    },
    borderLeft: '0.5rem solid #4286c7'
  }),
  methods: {
    reset (email) {
      this.login = !this.login
      if (email !== null) {
        this.snackbar.show = true
        this.snackbar.email = email
      }
    },
    loginFailed () {
      this.borderLeft = '0.5rem solid #e3342f'
    }
  },
  computed: {
    //
  },
  created () {
    //
  }
}
</script>
<style lang='scss'>
    #AuthComponent {
        .full-page {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            display: flex;
            .bottom-bar {
                text-align: center;
                position: absolute;
                bottom: 1rem;
                right: 0;
                left: 0;
                color: rgb(153, 153, 153);
            }
        }
        .card {
            width: 90vw;
            height: 60vh;
            border-radius: 0.5rem;
            box-shadow: 3px 3px 3px #c8c8c8;
            position: relative;
            background-color: #fff;
            border-left: 0.5rem solid #4286c7;
            @media screen and (min-width: 768px) {
                width: 35vw;
                height: 50vh;
            }
            .inner-card {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                background: none;
                overflow: hidden;
            }
            .logo {
                width: 6rem;
                height: 6rem;
                border-radius: 100%;
                background-image: url('/mwc-logo.png');
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                background-color: #ffffff;
                box-shadow: 2px 2px 2px #c8c8c8;
                position: absolute;
                top: -3rem;
            }
        }
    }
</style>
